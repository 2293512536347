import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import neihu1 from '../assets/images/neihu1.jpg'
import neihu3 from '../assets/images/neihu3.jpg'
import neihu4 from '../assets/images/neihu4.jpg'
import neihu5 from '../assets/images/neihu5.jpg'
import neihu6 from '../assets/images/neihu6.jpg'
import neihu7 from '../assets/images/neihu7.jpg'
import neihu8 from '../assets/images/neihu8.jpg'
import neihu9 from '../assets/images/neihu9.jpg'
import neihu10 from '../assets/images/neihu10.jpg'
import googleMap from '../assets/images/google-maps.png'

const Neihu = props => (
  <Layout>
    <Helmet>
      <title>台北內湖健身房｜三健客3musclers健身俱樂部</title>
      <meta name="description" content="三健客台北內湖健身房分館，提供多元的體能訓練課程、一對一教練課程及團體訓練課程，推薦若您有體態雕塑的需求，三健客內湖分館可以幫您免費諮詢及檢測，瞭解您的健身及運動需求，並幫您搭配出最適合您的健身計劃和飲食方針，陪您一起擁有最佳體態。" />
      <meta name="keywords" content="三健客,健身房,內湖健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>三健客內湖店</h1>
          </header>
          <span className="image main">
            <img src={neihu4} alt="" />
          </span>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span
                  className="image main"
                >
                  <img src={neihu1} alt=""/>
                </span>
              </div>
              <div className="col-4" s>
                <span className="image main">
                  <img
                    src={neihu3}
                    alt=""
                    
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image main" >
                  <img
                    src={neihu7}
                    alt=""
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu10} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu5} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu8} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img src={neihu9} alt="" />
                </span>
              </div>
              <div className="col-4">
                <span className="image fit">
                  <img
                    src={neihu6}
                    alt=""
                    style={{ width: '100%', height: '60%' }}
                  />
                </span>
              </div>
            </div>
          </div>
          <h3>服務項目</h3>
          <ul>
            <li>體態雕塑/肌力體能訓練/TRX懸吊訓練</li>
            <li>場地租借服務/一對一課程及團體課程</li>
            <li>特殊族群訓練-專業醫療人員提供諮詢及訓練</li>
            <li>飲食及營養諮詢 </li>
          </ul>
          <p>
            另有提供場地租借200元起
            歡迎私訊、電話或來店諮詢報名一對一或團體課程！
            <br /> 電話：02-8751-1915
            <br />
            地址：
            <a
              href="https://goo.gl/maps/o2jCnwMGA9ZVmRqJ8"
              style={{ textDecoration: 'none' }}
				  target='_blank'
            >台北市內湖區港華街18號
              <img 
                src={googleMap}
                style={{ width: '24px'}}
					 alt=''
              />
            </a>
          </p>
			 <p>
			 <iframe width="560" height="315" src="https://www.youtube.com/embed/ijelCtepI0s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen='true'></iframe>
			 </p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/3musclers/"
                className="icon alt fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/explore/locations/172586599964912/3musclers-/?hl=zh-tw"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
)

export default Neihu
